enum DebugLevel {
  TRACE = 'trace',
  LOG = 'log',
}

const getDebugLevel = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  let debugLevel = window.localStorage.getItem('__debug_level__');
  if (!debugLevel) {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('debug')) {
      debugLevel = searchParams.get('debug') || DebugLevel.LOG;
      window.localStorage.setItem('__debug_level__', debugLevel);
    }
  }
  return debugLevel;
};

export const debug = (...args: any[]) => {
  const debugLevel = getDebugLevel();
  if (!debugLevel) {
    return;
  }
  if (debugLevel === DebugLevel.TRACE) {
    console.trace(...args);
    return;
  }
  console.log(...args);
};
