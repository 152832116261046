import {
  useEnvironment,
  useExperiments,
  useSettingsEnvironment,
} from '@wix/yoshi-flow-editor';
import { IChallengeStylesVars } from '../components/ChallengesPage/stylesParams';
import { IChallengeListStylesVars } from '../components/ChallengesList/stylesParams';
import { debug } from '../utils/queryString';

export type CSSVars = IChallengeStylesVars | IChallengeListStylesVars;

export function formatVars(displayVar: CSSVars | CSSVars[]) {
  const vars = [].concat(displayVar);
  const fallbacks = vars.map((f) => `var(${f}`);
  const closingParens = new Array(vars.length).fill(')').join('');
  const display = fallbacks.join(', ') + closingParens;
  return display;
}

export function useCSSPBStyle() {
  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');

  return function ({ displayVar }: { displayVar: CSSVars | CSSVars[] }) {
    const display = formatVars(displayVar);
    return cssPBEnabled ? { display } : undefined;
  };
}

export function useCSSPBEnabled() {
  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');
  const { isCssPerBreakpoint } = useEnvironment();
  return cssPBEnabled && isCssPerBreakpoint;
}

export function useSettingsCSSPBEnabled() {
  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');
  const { isCssPerBreakpoint } = useSettingsEnvironment();
  debug('useSettingsCSSPBEnabled', cssPBEnabled, isCssPerBreakpoint);
  return cssPBEnabled && isCssPerBreakpoint;
}
