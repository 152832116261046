import React, { useCallback } from 'react';

import { useSettings } from '@wix/tpa-settings/react';
import { setWidthRestrictions } from '../../../services/resizeWindow';
import { withWixSDKWidget } from '../../../services/withWixSDK';
import { IWixSDKContext, useEnvironment } from '@wix/yoshi-flow-editor';

import { withProviders } from '../../../contexts/main/withProviders';
import { withGeneralData } from '../../../contexts/GeneralDataProvider/withGeneralData';
import { withChallengesListData } from '../../../contexts/ChallengesListDataProvider/withChallengesListData';
import { withSettingsEvents } from '../../../contexts/SettingsEvents/SettingsEvents';
import { withLocation } from '../../../contexts/Location/withLocation';
import { withPaidPlans } from '../../../contexts/PaidPlans/paidPlansContext';
import { applyProviders } from '../../../services/applyProviders';

import { ChallengesList } from './ChallengesList';

import { IChallengeListStateProps } from './interfaces';
import { orderChallenges } from './utils';
import { classes, st } from './Widget.st.css';
import challengeSettings, {
  CategoriesTabsChosenCategoryDefault,
} from '../settingsParams';

import type { IWixStatic } from '@wix/native-components-infra/dist/src/types/wix-sdk';
import {
  getCategoriesSetup,
  filterChallengesByCategory,
} from '../../../selectors/categories';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { filterChallenges } from './filterChallenges';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useCSSPBEnabled } from '../../../hooks/useCSSPBStyle';

const CategoriesMenu = loadable(() => {
  return import(
    /* webpackChunkName: "CategoriesList" */ './CategoriesMenu/CategoriesMenu'
  );
});

const EmptyList = loadable(() => import('./EmptyList/EmptyList'));

export const ChallengeList: FCWithChildren<
  IChallengeListStateProps & IWixSDKContext
> = ({ challengesListData, categoriesData, Wix, editorLoaded }) => {
  const settings = useSettings();
  const { isEditor, isMobile } = useEnvironment();

  const [isEditorLoaded, setIsEditorLoaded] = React.useState<boolean>(false);
  const [currentCategory, setCurrentCategory] = React.useState<string>(
    CategoriesTabsChosenCategoryDefault,
  );

  const {
    isCategoriesEnabled,
    chosenCategoryFromSettings,
    isDefaultCategory,
    isExistingInDBCategory,
    isShowCategoriesMenu,
  } = getCategoriesSetup(settings, categoriesData?.categories);
  const isUseChosenCategoryFromSettings =
    chosenCategoryFromSettings && !isDefaultCategory && isExistingInDBCategory;

  React.useEffect(() => {
    let removeResizeListener: () => void;

    if (isEditor && Wix) {
      removeResizeListener = setWidthRestrictions(Wix as IWixStatic);
    }

    setIsEditorLoaded(editorLoaded);

    return () => {
      if (isEditor && Wix) {
        removeResizeListener();
      }
    };
  }, []);

  const onCategoryChange = useCallback((categoryId: string) => {
    setCurrentCategory(categoryId);
  }, []);

  const chVisible = settings.get(challengeSettings.challengesVisible);

  const filteredChallenges = filterChallenges(
    challengesListData?.memberChallenges || [],
    chVisible,
  );
  const usedCategory = isUseChosenCategoryFromSettings
    ? chosenCategoryFromSettings
    : currentCategory;
  const filteredChallengesByCategory = filterChallengesByCategory(
    filteredChallenges || [],
    usedCategory,
  );
  const orderedChallenges = orderChallenges(
    isCategoriesEnabled ? filteredChallengesByCategory : filteredChallenges,
    settings,
    usedCategory,
  );

  const TitleTag = settings.get(challengeSettings.listTitleTag) || 'h1';
  const cssPBEnabled = useCSSPBEnabled();

  const showTitle = cssPBEnabled || settings.get(challengeSettings.dpListTitle);
  return (
    <div
      data-hook="challenge-list-wrapper"
      className={st(classes.root, {
        mobile: isMobile,
        titleAlignment: cssPBEnabled
          ? undefined
          : settings.get(challengeSettings.listTitleAlignment),
      })}
    >
      {showTitle ? (
        <TitleTag
          className={st(
            classes.listTitle,
            cssPBEnabled ? classes.opgDpListTitle : undefined,
          )}
          data-hook="card-title"
        >
          {settings.get(challengeSettings.listTitleText) as string}
        </TitleTag>
      ) : null}

      {isCategoriesEnabled && isShowCategoriesMenu ? (
        <CategoriesMenu
          categories={categoriesData.categories || []}
          onChange={onCategoryChange}
        />
      ) : null}

      {orderedChallenges?.length ? (
        <ChallengesList
          challenges={orderedChallenges}
          isEditorLoaded={isEditorLoaded}
        />
      ) : (
        <EmptyList />
      )}
    </div>
  );
};

ChallengeList.displayName = 'ChallengesList';

export default applyProviders(ChallengeList, [
  withProviders,
  withLocation,
  withSettingsEvents,
  withGeneralData,
  withChallengesListData,
  withPaidPlans,
  withWixSDKWidget,
]);
